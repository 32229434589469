import Plyr from "plyr";

export default class Video {
  constructor() {
    this.init();
  }

  init() {
    const jsPlayer = document.querySelectorAll(".js-player");
    if (jsPlayer) {
      Array.from(jsPlayer).map((p) => {
        let video = new Plyr(p, {
          controls: ["play-large"],
        });
        video.poster = p.getAttribute("data-plyr-poster");
      });
    }
  }
}
