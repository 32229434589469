import Swiper from 'swiper/bundle';

export default class Slider {
    constructor() {
        this.init();
    }

    init() {

        // === SWIPER TESTIMONIALS ===
        const swiperTestimonials = new Swiper(".swiper-testimonials", {
            grabCursor: true,
            effect: "fade",
            loop: true,
            autoplay: {
                delay: 3500,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".testimonials__content .swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".testimonials__content .swiper__navigation .swiper-next",
                prevEl: ".testimonials__content .swiper__navigation .swiper-prev",
            },
        });


        // === SWIPER GALLERY ===
        const swiperGallery = new Swiper(".swiper-gallery", {
            grabCursor: true,
            spaceBetween: 32,
            centeredSlides: true,
            slidesPerView: "auto",
            // loop: true,
            pagination: {
                el: ".gallery .swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: ".gallery .swiper__navigation .swiper-next",
                prevEl: ".gallery .swiper__navigation .swiper-prev",
            },
        });

        if (swiperTestimonials) {
            const swiperElement = document.querySelector('.swiper-testimonials');
            if (swiperElement) {
                swiperElement.addEventListener('mouseover', () => {
                    swiperTestimonials.autoplay.stop();
                });
                swiperElement.addEventListener('mouseout', () => {
                    swiperTestimonials.autoplay.start();
                });
            }
        }

    }
}
