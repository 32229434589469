export default class Nav {
    constructor() {
        this.init();
    }

    init() {


        function updateMainMarginTop() {
            const header = document.querySelector('header');
            const main = document.querySelector('main');

            if (header && main) {
                const headerHeight = header.offsetHeight;
                console.log("Hauteur de l'en-tête :", headerHeight);

                main.style.marginTop = `${headerHeight}px`;
            }
        }
        window.addEventListener('load', updateMainMarginTop);
        window.addEventListener('resize', updateMainMarginTop);


        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        if (viewportWidth > 1024) {
            const linkWithChildrens = document.querySelectorAll('.navigation-top__top-left > .menu-item.menu-item-has-children');
            if (linkWithChildrens) {
                function siblings(elem) {
                    const nodes = [...elem.parentNode.children];
                    return nodes.filter(node => node !== elem);
                }
                function toggleClass(elem, cls) {
                    elem.classList.toggle(cls);
                    siblings(elem).forEach(node => {
                        node.classList.remove(cls);
                    });
                }

                linkWithChildrens.forEach(el => {
                    el.addEventListener('click', function () {
                        toggleClass(this, 'bg-red');
                    })
                });
            }
        }
    }
}