import { gsap } from 'gsap';


// === ACCORDIONS ===
export function initAccordion() {
    const accordionItems = gsap.utils.toArray(".accordion-item");
    const accordionHeaders = gsap.utils.toArray(".accordion-header");
    const accordionAnimations = [];

    accordionItems.forEach(accordionItem => createAnimation(accordionItem));
    accordionHeaders.forEach(accordionHeader => {
        accordionHeader.addEventListener("click", () => toggleAnimation(accordionHeader));
    });

    function toggleAnimation(accordionHeader) {
        const isInsideTechnicalInformations = accordionHeader.closest('.technical-informations');
        const selectedAnimation = accordionHeader.animation;
        const isReversed = selectedAnimation.reversed();

        if (isInsideTechnicalInformations) {
            selectedAnimation.reversed(!isReversed);
        } else {
            accordionHeaders.forEach(header => {
                header.classList.remove("active");
                if (header.animation !== selectedAnimation) {
                    header.animation.reverse();
                }
            });
            selectedAnimation.reversed(!isReversed);
        }
        accordionHeader.classList.toggle("active", !selectedAnimation.reversed());
    }


    function createAnimation(accordion) {
        const accordionHeader = accordion.querySelector(".accordion-header");
        const accordionContent  = accordion.querySelector(".accordion-content");

        gsap.set(accordionContent, { height: "auto"})
        const tween = gsap.from(accordionContent, {
            height: 0,
            duration: 0.5,
            ease: "power1.inOut",
            reversed: true
        });
        accordionHeader.animation = tween;
        accordionAnimations.push(tween);

    }
}


export default class Animations {
    constructor() {
        this.init();
    }

    init() {
        initAccordion();
    }
}


// import $ from "jquery";
// import {gsap} from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
// import ScrollSmoother from "gsap/ScrollSmoother";
//
// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
// export default class Animations {
//     constructor() {
//         this.init();
//     }
//
//     init() {
//         window.smoother = ScrollSmoother.create({
//             wrapper: "#scrollContainer",
//             content: "#content",
//             smooth: 1.5,
//             smoothTouch: 0.1,
//             normalizeScroll: true,
//             ignoreMobileResize: true,
//             effects: true,
//             preventDefault: true
//         });
//
//         let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
//         if (viewportWidth < 1025) {
//             smoother.kill();
//         }
//
//         const title = $("#images-videos-block__style-4 .images-videos-block__rich-text > h3");
//         if (title) {
//             title.each(function () {
//                 $(this).next("p").addBack().wrapAll("<div class='wrapper' />")
//             });
//         }
//
//         // Parallax
//         if (document.querySelector('[anim-parallax]')) {
//             gsap.utils.toArray('[anim-parallax="up"]').forEach((section) => {
//                 const img = section.querySelector('img');
//                 if (img) {
//                     gsap.to(img, {
//                         yPercent: 20,
//                         ease: "none",
//                         scrollTrigger: {
//                             trigger: img,
//                             start: "top center",
//                             scrub: true
//                         }
//                     });
//                 }
//             });
//
//             gsap.utils.toArray('[anim-parallax="down"]').forEach((section) => {
//                 const img = section.querySelector('img');
//                 if (img) {
//                     gsap.to(img, {
//                         yPercent: -20,
//                         ease: "none",
//                         scrollTrigger: {
//                             trigger: img,
//                             start: "top center",
//                             scrub: true
//                         }
//                     });
//                 }
//             });
//
//             gsap.utils.toArray('[anim-parallax="element-up"]').forEach((section) => {
//                 if (section) {
//                     gsap.to(section, {
//                         yPercent: 12,
//                         ease: "none",
//                         scrollTrigger: {
//                             trigger: section,
//                             start: "top center",
//                             scrub: true
//                         }
//                     });
//                 }
//             });
//
//             gsap.utils.toArray('[anim-parallax="element-down"]').forEach((section) => {
//                 if (section) {
//                     gsap.to(section, {
//                         yPercent: -12,
//                         ease: "none",
//                         scrollTrigger: {
//                             trigger: section,
//                             start: "top center",
//                             scrub: true
//                         }
//                     });
//                 }
//             });
//         }
//     }
// }