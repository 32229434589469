import { gsap } from 'gsap';

export default class Mobile {
    constructor() {
        this.animating = false;
        this.animatingPanel = [];
        this.opened = false;
        this.classes = '';

        this.bindMethods();
        this.getElems();

        if (!this.header) return;

        this.initAnimation();
        this.events();
    }

    /**
     * Gets menu's DOM elements
     *
     * @returns {void}
     */
    getElems() {
        this.body = document.body;
        this.header = document.querySelector('.js-menu-header');
        this.logo = document.querySelector('.js-logo');

        this.btnToggle = document.querySelector('.js-mobil-toggle');
        this.btnClose = [...document.querySelectorAll('.js-mobil-close')];
        this.btnMouseLeave = [...document.querySelectorAll('.js-close-mousein')];

        this.itemsHover = this.header.querySelectorAll('.js-mouse-hover');

        this.menuMobil = document.querySelector('.js-menu-mobil');
        this.contentMobil = this.menuMobil.querySelector('.js-mobil-content');
        this.menuList = this.menuMobil.querySelector('.js-menu-list');

        this.itemsPanels = [...document.querySelectorAll('.js-panel-submenu')];
        this.closePanels = [...document.querySelectorAll('.js-panel-close')];
    }

    /**
     * Bind events
     *
     * @returns {void}
     */
    bindMethods() {
        this.toggle = this.toggle.bind(this);
        this.open = this.open.bind(this);
        this.openPanel = this.openPanel.bind(this);
        this.closePanel = this.closePanel.bind(this);
        this.close = this.close.bind(this);
    }
    /**
     * Launches events on DOM elements
     *
     * @returns {void}
     */
    events() {
        this.btnToggle.addEventListener('click', this.toggle);
        this.btnClose.forEach(element => {
            element.addEventListener('click', this.close)
        });

        this.itemsPanels.forEach(element => {
            element.addEventListener('click', this.openPanel)
        });

        this.btnMouseLeave.forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                var node = e.currentTarget.closest('.js-panel-desktop');
                node.blur();
                node.classList.add('hidden');
                setTimeout(() => {
                    node.classList.remove('hidden')
                }, 500);
            });
        });


        this.itemsHover.forEach(element => {
            element.addEventListener('mouseenter', (e) => { this.body.classList.add('overflow-hidden'); })
            element.addEventListener('mouseleave', (e) => { this.body.classList.remove('overflow-hidden'); })
        });

        this.closePanels.forEach(element => {
            element.addEventListener('click', this.closePanel)
        });
    }

    openPanel(e) {
        e.preventDefault();
        var panelNode = this.menuMobil.querySelector(e.currentTarget.dataset.href);
        if (this.panelNode) return;
        e.currentTarget.parentNode.classList.add('z-10');
        this.animatingPanel.push(panelNode);
        this.playPanel(panelNode);
        console.log(this.animatingPanel);
    }

    closePanel(e) {
        e.preventDefault();
        if (this.animatingPanel.length == 0) return;
        let popped = this.animatingPanel.pop();
        this.reversePanel(popped);
        console.log(this.animatingPanel);
    }

    reversePanel(panel) {
        gsap.fromTo(panel, {
            opacity: 1,
            x: "0%"
        }, {
            opacity: 0,
            duration: 0.4,
            x: "100%"
        });
    }

    playPanel(panel) {
        gsap.fromTo(panel, {
            opacity: 0,
            x: "100%"
        }, {
            opacity: 1,
            x: "0%",
            duration: 0.4,
        });
    }

    initHeaderScroll() {

    }

    refreshScrolltrigger() {
        this.scrolltrigger.refresh();
    }


    initAnimation() {
        this.animation = gsap.timeline({
            paused: true,
            onReverseComplete: () => {
                this.animating = false;
                this.opened = false;
            },
            onStart: () => {
                this.opened = true;
            },
            onComplete: () => {
                this.animating = false;
            },
        });

        this.animation.fromTo(this.menuMobil,
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 0.3 }
        )

        this.animation.fromTo(this.contentMobil,
            { xPercent: 100 },
            { xPercent: 0, duration: 0.4, ease: "power1.inOut" }
        )
    }


    open(e) {
        e.preventDefault();
        if (this.opened || this.animating) return;
        this.body.classList.add('overflow-hidden');
        this.btnToggle.classList.add('is-active');
        this.animation.play();
    }

    close(e, force) {
        e.preventDefault();
        if ((!this.opened || this.animating) && !force) return;
        this.btnToggle.classList.remove('is-active');
        this.body.classList.remove('overflow-hidden');
        this.animation.reverse();
    }

    /**
     * Toggles the menu opening/closing
     *
     * @returns {void}
     */
    toggle(e) {
        if (this.animating) return;
        console.log(this.opened);
        if (this.opened) this.close(e);
        else this.open(e);
    }

}
