export default class Viewport {
    constructor() {
        this.init();
    }

    init() {
        const config = {
            root: null,
            threshold: 0.35
        };

        const observer = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-active');
                }
            });
        }, config);

        const animFade = document.querySelectorAll('[anim-fade]');

        animFade.forEach(item => {
            observer.observe(item);
        });
    }
}