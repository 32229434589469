import '../sass/app.scss';

import "bootstrap/dist/js/bootstrap.bundle.min";
import NiceSelect from "nice-select2/src/js/nice-select2";
import Nav from "./components/nav";
import Animations from "./components/animations";
import Viewport from "./components/viewport";
import Video from "./components/video";
import Slider from "./components/slider";
import Tabs from "./components/tabs";
import Mobile from "./components/mobile";
import Accordeon from "./components/accordeon";
import "./components/pagination";

const initScripts = () => {
    new Viewport();
    new Video();
    new Slider();
    new Nav();
    new Animations();
    new Tabs();
    new Mobile();
    new Accordeon();
}

window.removeAllSubmenus = () => {
    let submenus = document.querySelectorAll('#site-navigations__mobile .navigation-top__submenu');
    submenus.forEach(el => el.classList.remove('open'));
}

// initialize scripts
document.addEventListener("DOMContentLoaded", function () {
    initScripts();

    // === DYNAMIC HEIGHT TITLE IN VERTICAL LIST LAYOUT ===
    function setDynamicHeight(selector, cssVarName) {
        const element = document.querySelector(selector);
        if (element) {
            const elementHeight = element.offsetHeight;
            document.documentElement.style.setProperty(cssVarName, `${elementHeight}px`);
        }
    }
    if (document.querySelector('.levelBlockTitle') ||
        document.querySelector('.studyCaseTitle') ||
        document.querySelector('.numberListTitle') ||
        document.querySelector('.solutionHighlightTitle') ||
        document.querySelector('.missionsTitle') ||
        document.querySelector('.questionAnswersTitle')) {
        setDynamicHeight('.levelBlockTitle', '--levelBlockHeight');
        setDynamicHeight('.studyCaseTitle', '--studyCaseHeight');
        setDynamicHeight('.numberListTitle', '--numberListHeight');
        setDynamicHeight('.solutionHighlightTitle', '--solutionHighlightHeight');
        setDynamicHeight('.missionsTitle', '--missionsHeight');
        setDynamicHeight('.questionAnswersTitle', '--questionAnswersHeight');
    }

    // === DYNAMIC WIDTH GRID DECO IMG ===
    function keywordsImageOverflow() {
        const containers = document.querySelectorAll('.keywords-block .grid__deco');

        containers.forEach((container) => {
            const img = container.querySelector('img');
            const containerWidth = container.clientWidth;
            const imgWidth = img.naturalWidth;
            const spacerXlInPixels = 88;
            const overflowAmount = imgWidth - containerWidth + spacerXlInPixels;

            img.style.width = `calc(100% + ${overflowAmount}px)`;
        });
    }
    window.addEventListener('load', keywordsImageOverflow);
    window.addEventListener('resize', keywordsImageOverflow);



    // === CF7 FORM NOT-VALID ===
    function manageClass(element) {
        const formGroup = element.closest('.form-group');
        if (element.classList.contains('wpcf7-not-valid')) {
            formGroup.classList.add('not-valid');
        } else {
            formGroup.classList.remove('not-valid');
        }
    }
    function removeClassesOnFocusOrClick(event) {
        const inputElement = event.target;
        const formGroup = inputElement.closest('.form-group');
        const notValidTip = inputElement.closest('.wpcf7-form-control-wrap').querySelector('.wpcf7-not-valid-tip');

        inputElement.classList.remove('wpcf7-not-valid');
        formGroup.classList.remove('not-valid');

        if (notValidTip) {
            notValidTip.style.display = 'none';
        }
    }
    const config = {
        attributes: true,
        attributeFilter: ['class'],
    };
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            manageClass(mutation.target);
        });
    });
    const formControls = document.querySelectorAll('.wpcf7-form-control');
    formControls.forEach((formControl) => {
        observer.observe(formControl, config);
        formControl.addEventListener('click', removeClassesOnFocusOrClick);
        formControl.addEventListener('focus', removeClassesOnFocusOrClick);
    });


    document.querySelectorAll('.form-select').forEach((selectElement) => {
        new NiceSelect(selectElement, { searchable: false });
    });

    function adjustElementStyles() {
        if (document.body.classList.contains('single-lab')) {
            const ctaDemoSection = document.querySelector('.section--cta-demo');

            if (ctaDemoSection) {
                const previousElement = ctaDemoSection.previousElementSibling;

                if (previousElement) {
                    const screenWidth = window.innerWidth;
                    if (screenWidth < 1200) {
                        previousElement.style.marginBottom = '-6rem';
                        previousElement.style.paddingBottom = '12rem';
                    } else {
                        previousElement.style.marginBottom = '-16rem';
                        previousElement.style.paddingBottom = '16rem';
                    }
                    previousElement.style.zIndex = '10';
                    previousElement.style.position = 'relative';
                }
            }
        }
    }
    adjustElementStyles();
    window.addEventListener('resize', adjustElementStyles);

});
