import { gsap } from 'gsap';

export default class Accordion {
  constructor() {
    this.accordion = gsap.utils.toArray(".js-accordion");
    this.AllAccordions = [];

    this.accordion.forEach(elt => {
      const accordion = {
        opened: false,
        animating: false,
        el: elt,
        title: elt.querySelector('.js-accordion-title'),
        content: elt.querySelector('.js-accordion-content'),
      }
      accordion.trigger = accordion.title.addEventListener('click', this.onTriggerClick.bind(accordion));
      accordion.opened ? elt.classList.add('is-opened') : elt.classList.remove('is-opened');

      this.AllAccordions.push(accordion);
    });
  }


  onTriggerClick() {
    console.log(this);
    if (this.animating) return;
    this.animating = true;
    this.el.classList.toggle('is-opened');
    gsap.fromTo(
      this.content,
      {
        y: this.opened ? 0 : 0 - this.translate,
      },
      {
        height: this.opened ? 0 : this.content.scrollHeight,
        duration: 0.3,
        ease: 'ease-in',
        y: this.opened ? 0 - this.translate : 0,
        onComplete: () => {
          this.animating = false;
          this.opened = !this.opened;
          if (this.opened) {
            this.content.style.height = 'auto';
          }
        },
      },
    );
  }

}
