export default class Tabs {
    constructor() {
        this.init();
    }

    init() {
        let goTabs = (buttons, content) => {
            let tabs = document.querySelectorAll(buttons + " a");

            for (let i = 0; i < tabs.length; i++) {
                tabs[i].addEventListener("click", function (tabClickEvent) {
                    tabClickEvent.preventDefault();
                    let i;
                    for (i = 0; i < tabs.length; i++) {
                        tabs[i].classList.remove("active");
                    }
                    const clickedTab = tabClickEvent.currentTarget;
                    clickedTab.classList.add("active");
                    const myContentPanes = document.querySelectorAll(content);
                    for (i = 0; i < myContentPanes.length; i++) {
                        myContentPanes[i].classList.remove("active");
                    }

                    const anchorReference = tabClickEvent.target;
                    const activePaneId = anchorReference.getAttribute("href");
                    const activePane = document.querySelector(activePaneId);

                    //console.log(anchorReference);
                    //console.log(activePaneId);

                    activePane.classList.add("active");
                });
            }
        }

        goTabs('.nav-tabs-members', '.tab-pane-members');
        goTabs('.nav-tabs-history-top', '.tab-pane-history-top');
        goTabs('.nav-tabs-history-bottom', '.tab-pane-history-bottom');
    }
}